
import { defineComponent } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import VehicleMakeAndModel from "@/components/reusable/VehicleMakeAndModel.vue";
import VehicleRegAndChassisNumber from "@/components/reusable/VehicleRegAndChassisNumber.vue";
import VehicleUsage from "@/components/reusable/VehicleUsage.vue";
import ImagesUploader from "@/components/reusable/ImagesUploader.vue";
import VehicleColors from "@/views/reusable/buy-insurance/motor-insurance/vehicle-colours.json";
import VehicleBodyTypes from "@/views/reusable/buy-insurance/motor-insurance/vehicle-body-types.json";
import "viewerjs/dist/viewer.css";
import $ from "jquery";

export default defineComponent({
  name: "motor-comprehensive-component",
  components: {
    Field,
    ErrorMessage,
    VehicleMakeAndModel,
    VehicleRegAndChassisNumber,
    ImagesUploader,
    VehicleUsage,
  },
  props: {
    covers: Array,
    insurancePackage: String,
    motorPolicyId: String,
    premiumCalculationRoute: String,
    expiryDate: String,
    vehicleBrands: Array,
  },
  data() {
    return {
      colorChart: ["/media/packages/vehicle-color-chart.jpeg"],
      images: [
        "/media/packages/car-body-types-1.jpeg",
        "/media/packages/car-body-types-2.jpeg",
      ],
      rates: {
        comprehensiveMotorRate: "",
      },
      underwriters: [],
      currentDate: variables.currentDate(),
      metadata: [
        {
          vehicleOwner: "",
          package: "",
          vehicleValue: "",
          vehiclePremium: 0.0 as number,
          vehicleMake: "",
          vehicleModel: "",
          vehicleRegistrationNumber: "",
          chassisNumber: "",
          engineNumber: "",
          engineCapacity: "",
          yearManufactured: "",
          vehicleColor: "",
          bodyType: "",
          vehicleLicense: "",
          vehicleImages: "",
          startDate: "",
        },
      ],
      totalPremium: 0.0 as number,
      totalSumInsured: 0.0 as number,
      insurancePackageType: this.insurancePackage,
      businessClass: "MOT",
      vehicleColors: VehicleColors,
      vehicleBodyTypes: VehicleBodyTypes.commercial,
      selectedCoverId: "",
    };
  },
  methods: {
    premiumByStartDate(position) {
      // const additionStartDate = $(`#addition-start-date-${position}`).val();
      const additionStartDate = $("#addition-start-date").val();

      if (additionStartDate) {
        const vehicleValue = $(`#vehicle-value-${position}`);

        if (vehicleValue.val()?.length > 6) {
          const event = {
            target: vehicleValue[0],
            value: vehicleValue.val(),
            type: "input",
            id: `vehicle-value-${position}`,
          };

          this.premium(event, position);
        }
      }
    },
    premium(event, position) {
      // Add comma and allow decimal point vehicle value field
      variables.numberFormatter(event.target.id);
      const newValue = event.target.value;

      // Remove comma from vehicle value
      let value: any = variables.removeComma(event.target.value);

      // Check if value is a number
      if (isNaN(value) == false) {
        // Convert value to a string
        value = String(value);
        // const additionStartDate = $(`#addition-start-date-${position}`).val();
        const additionStartDate = $("#addition-start-date").val();
        this.metadata[position].startDate = additionStartDate;

        if (
          JwtService.getToken() &&
          additionStartDate?.length > 0 &&
          value?.toString()?.length > 6
        ) {
          const payload = {
            businessClass: "MOT",
            value: value,
            rate: event.target.accessKey,
            package: this.selectedCoverId,
            startDate: additionStartDate,
          } as object;

          ApiService.setHeader();
          ApiService.post(`${this.premiumCalculationRoute}`, payload)
            .then(({ data }) => {
              // Calculate premium from vehicle value
              variables.premiumCalculator(
                1,
                data.data,
                event.target.ariaValueText
              );

              // Call vehicle premium calculator
              this.vehiclePremiumCalculator(1, data.data, position);

              // Append total premium value tot total premium field
              this.totalPremium = variables.totalPremiumValue();
              this.$nextTick(() => (event.target.value = newValue));
            })
            .catch(function(error) {
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      } else {
        return;
      }

      // Convert value to a number
      value = Number(value);

      // Append total sum insured value tot total sum insured field
      this.totalSumInsured = variables.totalSumInsured(
        value,
        event.target.title
      );
    },
    addDynamicField() {
      this.metadata.push({
        vehicleOwner: "",
        package: "",
        vehicleValue: "",
        vehiclePremium: 0.0 as number,
        vehicleMake: "",
        vehicleModel: "",
        vehicleRegistrationNumber: "",
        chassisNumber: "",
        engineNumber: "",
        engineCapacity: "",
        yearManufactured: "",
        vehicleColor: "",
        bodyType: "",
        vehicleLicense: "",
        vehicleImages: "",
        startDate: "",
      });

      variables.toastAlert("Row added", "success");
    },
    removeDynamicField(index) {
      // Remove dynamic field
      // this.metadata.splice(index, 1);
      $(".remove-" + index).remove();
      variables.toastAlert("Row removed", "success");

      setTimeout(() => {
        // Update total premium
        this.totalPremium = variables.totalPremiumValue();
      }, 200);
    },
    vehicleLicenseSelected(event, position) {
      this.metadata[position].vehicleLicense = event.target.files[0];
    },
    vehicleImagesSelected(event, position) {
      this.metadata[position].vehicleImages = event.target.files;
    },
    showBodyTypes() {
      this.$viewerApi({
        images: this.images,
      });
    },
    showColorChart() {
      this.$viewerApi({
        images: this.colorChart,
      });
    },
    vehiclePremiumCalculator(rate: number, value: number, position: number) {
      this.metadata[position].vehiclePremium = +variables.vehiclePremium(
        rate,
        value
      );
    },
    motorCoverPublicId(publicId: string): string {
      return (this.selectedCoverId = publicId);
    },
    updateVehicleValue(position, rate) {
      return (this.metadata[position].vehicleValue = rate);
    },
  },
});
